.react-datepicker-wrapper {
  display: block;
  .react-datepicker__close-icon {
    position: absolute;
    right: 10px;
    padding: 0;
    cursor: default;
    font-family: 'Arial Narrow';
    &::after {
      font-size: 24px;
      background-color: transparent;
      color: #ccc;
      width: 20px;
      height: 20px;
      padding: 0;
      cursor: default;
    }
    &:hover {
      &::after {
        color: #888;
      }
    }
  }
}
