.tooltip-list {
  list-style-type: disc;
  padding-left: 20px;
  &.top-margin {
    margin-top: 10px;
  }
}

.tooltip-mobile {
  max-width: 250px;
}
