@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "SweetSansPro";
  src: url("./assets/fonts/sweetsanspro/SweetSansProBold.otf");
}

body {
  @apply font-montserrat bg-dorothy-gray;
}

.menu-link {
  @apply text-white/60 hover:text-white flex items-center mb-6 px-2 text-base font-normal rounded-md;
}
.menu-link-active {
  @apply text-white flex items-center mb-6 px-2 text-base rounded-md font-bold;
}

.rc-pagination {
  user-select: none;
  overflow: hidden;
  display: inline-block;
  font-size: 12px;
  li {
    display: block;
    float: left;
    margin: 0 2px;
    a {
      display: block;
      width: auto;
      min-width: 40px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: solid 1px #999;
      border-radius: 3px;
      cursor: pointer;
    }
    &.rc-pagination-item-active {
      a {
        background-color: #222844;
        border-color: #222844;
        color: #ffffff;
      }
    }
    &.rc-pagination-disabled {
      button {
        opacity: 0.4;
      }
    }
    button {
      display: block;
      justify-items: center;
      align-items: center;
      width: auto;
      min-width: 40px;
      height: 32px;
      border: solid 1px #222844;
      border-radius: 3px;
      border: solid 1px #999;
    }
  }
}

.react-select-input [class*="placeholder"] {
  font-size: 0.875rem;
  line-height: 1.25rem;
  @apply text-gray-400;
}

.react-select-input > div:first-of-type {
  min-height: 44px;
  @apply border-gray-300;
}
.react-select-input.rs-error > div:first-of-type {
  @apply border-error;
}

.full-button {
  button {
    width: 100% !important;
    justify-content: center;
  }
}
