.wrapper {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.frame {
  width: 100%;
  overflow: hidden;
  background-color: #EEF0F1;
  border-radius: 12px;
  position: relative;
}

.box-container {
  display: flex;
}

.box {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 50px;
}

.left,
.right {
  border-radius: 3px;
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #fff;
  z-index: 3;
  top: 50%;
  margin-top: -12px;
  border: solid 1px #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.left {
  left: 16px;
}
.right {
  right: 16px;
}

.dots-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #e0e0e0;
  margin: 6px 4px;
  cursor: pointer;
}

.active {
  background: #e79c26;
}
